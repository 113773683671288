import React, { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "../src/Sidebar.css";
import { MdOutlineLegendToggle } from "react-icons/md";
import logo from "../src/Img/login.png";
import { Container, Offcanvas, Navbar, Nav, Image } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { FaBitcoin } from "react-icons/fa";
import { apiurl } from "../src/env";
import Modal from "react-bootstrap/Modal";
import p14 from "../src/Img/Animation - 1705480758874.json";
import Lottie from "lottie-react";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import Form from "react-bootstrap/Form";
import { FaUser } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";

function SideBar() {
  const Navigate = useNavigate();
  const location = useLocation();
  const selectRef = useRef(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClosetoggal = () => setShow(false);
  const handleShowtoggal = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const offsetValue = -56;

  const handleLogout = () => {
    localStorage.removeItem("token");
    Navigate("/");
  };

  const useractive = async (handleClose1) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${apiurl}/activate-plan`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "001") {
            toast(result.message);
            handleClose1();
          } else if (result.status == "007") {
            toast(result.message);
            handleClose1();
          } else {
            toast(result.message);
            handleClose1();
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {}
  };

  const [selectedOption, setSelectedOption] = useState();

  return (
    <div>
      <ToastContainer />
      <div
        className="container-fluid desktop"
        style={{ backgroundColor: "black" }}
      >
        <div
          className="row px-2"
          style={{ height: "100vh", overflowY: "scroll" }}
        >
          <div className="col-lg-12 col-sm-12">
            <div
              className="sidbar d-flex flex-column flex-shrink-0 "
              style={{ height: "158vh", overflowY: "scroll" }}
            >
              <div className="mt-2">
                <img
                  src={logo}
                  alt="logo"
                  className="sidebarLogo"
                  style={{ width: "100%", height: "50px" }}
                />
              </div>

              <ul className="nav nav-pills flex-column mt-3">
                <li className="nav-item">
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    <i className="bi bi-microsoft me-2"></i>
                    Dashboard
                  </NavLink>
                </li>

                <li className="nav-item mt-3">
                  <NavLink
                    to="/MyTeam"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-microsoft-teams me-2"></i>
                    My Team
                  </NavLink>
                </li>
                <li className="nav-item mt-3">
                  <NavLink
                    to="/DepositFund"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-cash-stack me-2"></i>
                    Deposit Funds
                  </NavLink>
                </li>
                <li className="nav-item mt-3">
                  <NavLink to="/P2P" className="nav-link" aria-current="page">
                    <i class="bi bi-arrow-left-right me-2"></i>P 2 P Transfer
                  </NavLink>
                </li>
                <li className="nav-item mt-3">
                  <NavLink
                    to="/Transaction"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-wallet2 me-2"></i>
                    Transactions
                  </NavLink>
                </li>
                <li className="nav-item mt-3">
                  <NavLink
                    to="/PurchasePaln"
                    className="nav-link"
                    aria-current="page"
                  >
                    <i class="bi bi-receipt me-2"></i>
                    Plan Purchase
                  </NavLink>
                </li>

                <li className="nav-item mt-3">
                  <NavLink
                    to="/Income"
                    className="nav-link"
                    aria-current="page"
                  >
                    <FaBitcoin size={20} />
                    <span className="ms-2">Incomes</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item mt-3">
                <NavLink to="/User" className="nav-link" aria-current="page">
                <i class="bi bi-person-circle me-2"></i>
                  Profile
                </NavLink>
              </li> */}
              </ul>
              <li
                className="nav-item text-center  mt-5 b-0 "
                style={{
                  color: "black",
                  listStyle: "none",
                  background: "#fff",
                  padding: "10px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  handleLogout();
                }}
              >
                <div style={{ cursor: "pointer" }}>
                  <h6 style={{ color: "#000" }}>
                    <i
                      class="bi bi-box-arrow-right me-2 "
                      style={{ color: "black" }}
                    ></i>
                    Logout
                  </h6>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>

      <Navbar
        bg="dark"
        variant="dark"
        className="mobileview"
        expand={false}
        fixed="top"
        style={{ backgroundColor: "red", width: "100%" }}
      >
        <Container fluid>
          <Navbar.Toggle onClick={handleShowtoggal} style={{ width: "50px" }} />
          <div className="usericn">
            {/* <NavLink to="/Home" style={{ textDecoration: "none",borderBottom:'none'}}>
            <i className="bi bi-person-circle mb-2 mx-4" style={{ color: '#fff', fontSize: "30px",border:'none'}}></i>
          </NavLink> */}
            <NavLink to="/PurchasePaln" style={{ textDecoration: "none" }}>
              {" "}
              <button class="button-41 " role="button">
                <span class="text">Activate Now</span>
              </button>
            </NavLink>
          </div>

          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
            style={{ width: "80%", backgroundColor: "#000425" }}
            className="sidbar"
            show={show}
            onHide={handleClosetoggal}
          >
            <Offcanvas.Header>
              <IoMdClose
                onClick={handleClosetoggal}
                style={{ fontSize: "2rem" }}
              />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="sidbar justify-content-start flex-grow-1 pe-3 offcanvas--menu">
                <Link
                  to="/Home"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                  aria-current="page"
                >
                  <i className="bi bi-microsoft me-2"></i>
                  Dashboard
                </Link>
                <Link
                  to="/MyTeam"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3 text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-microsoft-teams me-2"></i>
                  My Team
                </Link>
                <Link
                  to="/P2P"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3 text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-arrow-left-right me-2"></i>P 2 P Transfer
                </Link>

                <Link
                  to="/DepositFund"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-cash-stack me-2"></i>
                  Deposit Funds
                </Link>

                <Link
                  to="/Transaction"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-wallet2 me-2"></i>
                  Transactions
                </Link>

                <Link
                  to="/PurchasePaln"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-receipt me-2"></i>
                  plan Purchase
                </Link>
                <Link
                  to="/Income"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-receipt me-2"></i>
                  Incomes
                </Link>
                {/*<Accordion defaultActiveKey="1" style={{border:'none',backgroundColor: 'transparent',}}>
      <Accordion.Item eventKey="0" style={{border:'none',}}>
        <Accordion.Header> Profile Management</Accordion.Header>
        <Accordion.Body>
        <li className="nav-item" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    <i className=" bi-person-circle me-2"></i>
                    Profile
                  </NavLink>
                </li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>


    <Accordion defaultActiveKey="2" style={{border:'none',backgroundColor: 'transparent',}} className='mt-1'>
      <Accordion.Item eventKey="0" style={{border:'none',}}>
        <Accordion.Header>Network Area</Accordion.Header>
        <Accordion.Body>
        <li className="nav-item" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    <i className=" bi-person-circle me-2"></i>
                    Genelogy Tree
                  </NavLink>
                
                </li>
                <li className="nav-item mt-2" style={{width:'100%'}}>
                <NavLink to="/Home" className="nav-link" aria-current="page">
                    <i className=" bi-person-circle me-2"></i>
                    Direct Spro
                  </NavLink>
                </li>

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>




    <Accordion defaultActiveKey="3" style={{border:'none',backgroundColor: 'transparent',}}>
      <Accordion.Item eventKey="0" style={{border:'none',}}>
        <Accordion.Header> Income Area</Accordion.Header>
        <Accordion.Body>
        <li className="nav-item" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Matching Income
                  </NavLink>
                </li>
                <li className="nav-item mt-1" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page" style={{}}>
                    Referral Bonus
                  </NavLink>
                </li>
                 <li className="nav-item mt-1" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page" style={{fontSize:'14px',textDecoration:'none'}}>
                    Life Time Reword Income
                  </NavLink>
                </li>
                 <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Downline Automation Income
                  </NavLink>
                </li> 
                <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Self Complete Bonus
                  </NavLink>
                </li>
                <li className="nav-item mt-2"  style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Network 14 Income
                  </NavLink>
                </li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>



    <Accordion defaultActiveKey="4" style={{border:'none',backgroundColor: 'transparent',}}>
      <Accordion.Item eventKey="0" style={{border:'none',backgroundColor:'#2f323b'}}>
        <Accordion.Header>  Wallet Area</Accordion.Header>
        <Accordion.Body>
        <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    P2P  Wallet                   </NavLink>
                </li>
                <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Fund Convert 
                  </NavLink>
                </li>
                <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Income Wallet (History)
                  </NavLink>
                </li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    <Accordion defaultActiveKey="5" style={{border:'none',backgroundColor: 'transparent',}}>
      <Accordion.Item eventKey="0" style={{border:'none',backgroundColor:'#2f323b'}}>
        <Accordion.Header > Topup</Accordion.Header>
        <Accordion.Body style={{backgroundColor:'#2f323b'}}>
        <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    Active ID 
                  </NavLink>
                </li>
                <li className="nav-item mt-2" style={{width:'100%'}}>
                  <NavLink to="/Home" className="nav-link" aria-current="page">
                    History
                  </NavLink>
                </li>
        </Accordion.Body>
      </Accordion.Item>
        </Accordion>*/}
                {/* <Link
                  to="/User"
                  spy={true}
                  smooth={true}
                  offset={offsetValue}
                  duration={500}
                  className="p-3  text-decoration-none"
                  style={{ color: "#fff" }}
                >
                  <i class="bi bi-person-circle me-2"></i>
                  Profile
                </Link> */}
              </Nav>
            </Offcanvas.Body>
            <button
              onClick={() => {
                handleLogout();
              }}
              style={{ color: "#fff" }}
            >
              <i
                class="bi bi-box-arrow-right me-2 "
                style={{ color: "#fff" }}
              ></i>
              Logout
            </button>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Body
          style={{
            background: "#000",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            className="Lottie w-25" // Add the class name here
            animationData={p14} // replace with your animation data
            loop
            autoplay
            style={{ marginLeft: "170px" }}
          />
          <p style={{ textAlign: "center", color: "#fff", fontSize: "18px" }}>
            Are You Sure, You Want To Activate <br /> Plan Amount: $30{" "}
          </p>
        </Modal.Body>
        <Modal.Footer style={{ background: "#000" }}>
          <Button variant="secondary" onClick={handleClose1}>
            Go Back
          </Button>
          <Button variant="primary" onClick={() => useractive(handleClose1)}>
            Activate
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SideBar;

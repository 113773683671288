import React,{useState,useEffect} from 'react';
import '../component/Text.css';
import { apiurl } from '../env';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const UserData = () => {

const [data, setdata] = useState([]);
const [leftMessage, setLeftMessage] = useState("");
const [rightMessage, setRightMessage] = useState("");
const [copySuccess, setCopySuccess] = useState(null);
const [copySuccess1, setCopySuccess1] = useState(null);
const [email, setemail] = useState();
const [number, setnumber] = useState();
const [name, setName] = useState();
const [loading, setLoading] = useState(false);

const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const handleCopy = (message) => {
  navigator.clipboard.writeText(message)
    .then(() => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    })
    .catch((err) => {
      console.error('Copy failed:', err);
      setCopySuccess(false);

    });
};


const handleCopy1 = (message) => {
  navigator.clipboard.writeText(message)
    .then(() => {
      setCopySuccess1(true);
      setTimeout(() => {
      setCopySuccess1(false)

      }, 3000); // Reset copy status after 3 seconds
    })
    .catch((err) => {
      console.error('Copy failed:', err);
      setCopySuccess1(false)

    });
};


const profileApi = async () => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    const response = await fetch(`${apiurl}/get-profile`, requestOptions);
    const result = await response.json();

    console.log(result);

    // Assuming result.data.username contains the username
    localStorage.setItem("userid", result.data.username);

    setdata(result.data);
    setemail(result.data.email);
    setnumber(result.data.number);
    setName(result.data.name);
    localStorage.setItem("name", result.data.name);
    setLeftMessage(result.left);
    setRightMessage(result.right);
  } catch (error) {
    console.log('error', error);
  }
}


const useradd = async () => {
  setLoading(true);

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

  var formdata = new FormData();
  formdata.append("name", name);
  formdata.append("number", number);
  formdata.append("email", email);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  try {
    const response = await fetch(`${apiurl}/edit-profile`, requestOptions);
    const result = await response.json();

    if (result.status === '001') {
      await toast(result.message);
      handleClose();
      window.location.reload()
    } else {
      toast.error(result.message);
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  profileApi()
}, [])



  return (
  <>
  <ToastContainer/>
  <section >
        <div className="container-fluid desktop px-3" style={{background: "#995B00", width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {data.name}</p>
            </div>
          </div>
        </div>
      </section>
      <div>
          <section>
            <div className='container mt-5'>
              <div className='row'>
                <div className='col-lg-1'>
                </div>
                <div className='col-lg-10' >
                  <div class="main p-2" >
                    <h2 className='text-black'>User Profile</h2>
                    <div class="card3">
                    <p className='p'><button to='' onClick={handleShow}><i class="bi bi-pencil" style={{color:'#fff'}}></i></button></p>
                      <div class="card-body">
                        <table>
                          <tbody>
                            <tr>
                              <td className='td'>Name</td>
                              <td>:</td>
                              <td>{data.name}</td>
                              <td className='pd'><button to='' onClick={handleShow}><i class="bi bi-pencil" style={{color:'#fff'}}></i></button></td>
                            </tr>
                            <tr>
                              <td className='td'>Email</td>
                              <td>:</td>
                              <td>{data.email}</td>
                            </tr>
                            <tr>
                              <td className='td'>Username</td>
                              <td>:</td>
                              <td>{data.username}</td>
                            </tr>
                            <tr>
                              <td className='td'>Number</td>
                              <td>:</td>
                              <td>{data.number}</td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>

      <Modal show={show} onHide={handleClose} >
        <Modal.Header  style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)',color:'#fff'}}>
        <button onClick={handleClose} style={{color:'#fff'}}>X</button>

        </Modal.Header>
        <Modal.Body style={{background: 'linear-gradient(to right bottom, #4f525c, #000)'}}>
        <section >
              <div>
                <div>
                  <div className="mb-3 p-4">
                    <label htmlFor="exampleFormControlInput1" className="f">
                      Name
                    </label>
                    <input
                      style={{ color: '#878d96' }}
                      type="text"
                      value={name}
                      onChange={(e) => { setName(e.target.value) }}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Name"
                    />

                    <label htmlFor="exampleFormControlInput2" className="f mt-3">
                      Enter Email
                    </label>
                    <input
                      style={{ color: '#878d96' }}
                      value={email}
                      onChange={(e) => { setemail(e.target.value) }}
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput2"
                      placeholder="Enter Email"
                    />

                    <label htmlFor="exampleFormControlInput2" className="f mt-3">
                      Enter Number
                    </label>
                    <input
                      style={{ color: '#878d96'  }}
                      value={number}
                      onChange={(e) => { setnumber(e.target.value) }}
                      type="number"
                      className="form-control"
                      id="exampleFormControlInput2"
                      placeholder="Enter Number"
                    />

                    <button className="btn1 mt-4" onClick={()=>useradd()} style={{ cursor: 'pointer' }}>
                      {loading ? 'Loading...' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
          </section>
        
        </Modal.Body>
      </Modal>
         
  </>
  )
}

export default UserData
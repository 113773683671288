import React, { useState,useEffect } from 'react';
import './Login.css';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaCopy } from "react-icons/fa6";
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const SignUp=() =>{
  const [searchParams] = useSearchParams();

  const Navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [name, setName] = useState('');
  const [refid, setRefid] = useState(searchParams.get("refid") || '');
  const [refName, setRefName] = useState(searchParams.get("name") || '');
  const [loading, setLoading] = useState(false);  
  const [apiName, setApiName] = useState({});
  const [apiPass, setApiPass] = useState('');
  const [ Email, setApiemail] = useState(''); 
  const passwordsMatch = password === passwordConfirmation;
  const [copySuccess, setCopySuccess] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility
 
  const [showConfirmation, setShowConfirmation] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmationVisibility = () => {
    setShowConfirmation(!showConfirmation);
  };



const signup =async()=>{
  setLoading(true)
  const formdata = new FormData();
formdata.append("name", name);
formdata.append("email", email);
formdata.append("number", number);
formdata.append("password", password);
formdata.append("password_confirmation", passwordConfirmation);
formdata.append("refer_id", refid);

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch("https://mywealthventures.com/api/register", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success === true){
      toast(result.message)
      handleShow()
      setTimeout(() => {
        //  Navigate('/');
       }, 5000);
       setApiName(result.data.name); 
       setApiPass(result.data.password); 
       setApiemail(result.data.email); 
       setRefName(result.data.username); 
    }
    else{
      toast(result.message)
    }
  })
  .catch((error) => console.error(error))
   .finally(()=>{setLoading(false)});
}

   

//   const signup = async()=>{
//     setLoading(true);
//     var myHeaders = new Headers();

// const formdata = new FormData();
// formdata.append("name", name);
// formdata.append("email", email);
// formdata.append("number", number);
// formdata.append("password", password);
// formdata.append("password_confirmation", password_c);
// formdata.append("position", selectedOption);
// formdata.append("refer_id", refid.slice(0,-1));

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: formdata,
//   redirect: 'follow'
// };

// fetch(`https://mywealthventures.com/api/register`, requestOptions)
//   .then(response => response.json())
//   .then(result => {console.log(result)
//     if (result.success === true) {
//       Navigate('/') 

//       setApiName(result.username);
//       setApiPass(result.password);
//       setName('');
//       setRefid('');
//       setRefName('');
//       setEmail('');
//       setNumber('')
//       setPassword('')
//       setPasswordConfirmation('')
//       setSelectedOption('')
//     } else {
//       toast.error(result.message);
//     }
  
//   })
//   .catch(error => console.log(error))
//   .finally(()=>{setLoading(false)})
//   }
const handleCopyClick = (textToCopy) => {
  // Create a temporary input element
  const tempInput = document.createElement('input');
  // Set its value to the text you want to copy
  tempInput.value = textToCopy;
  // Append it to the body
  document.body.appendChild(tempInput);
  // Select the text in the input element
  tempInput.select();
  // Execute copy command
  document.execCommand('copy');
  // Remove the temporary input element
  document.body.removeChild(tempInput);

  // Set copySuccess state to show a success message
  setCopySuccess(<span style={{color:'green', paddingTop:'15px', textAlign:'center'}}>{textToCopy} copied to clipboard! </span>);
};
   
   
  return (
    <>
<ToastContainer/> 



<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
        <div>
      <h5>
        Name: {apiName} <span className='ms-3' onClick={() => handleCopyClick(apiName)}><FaCopy /></span>
      </h5>
      <h5>
        Email: {Email} <span className='ms-3' onClick={() => handleCopyClick(Email)}><FaCopy /></span>
      </h5>
      <h5>
        Password: {apiPass} <span className='ms-3' onClick={() => handleCopyClick(apiPass)}><FaCopy /></span>
      </h5>
      <h5>
        Username: {refName} <span className='ms-3' onClick={() => handleCopyClick(refName)}><FaCopy /></span>
      </h5>
      {copySuccess && <p>{copySuccess}</p>}
    </div>
        </Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={()=>{Navigate('/')}}>Go to Login</Button>
        </Modal.Footer>
      </Modal>


      <div class="container app">

<div class="login1">

    <h1>Sign Up </h1>

    <div className='form1'>
        <div class="input-box">
        </div>

         <div className='row'>
         <div className='col-lg-6'>
         <div className="input-box">
        <label htmlFor="email" style={{color:'#fff'}}> Name</label>
              <input
                type="name"
                               value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
         </div>
         <div className='col-lg-6'>
         <div className="input-box">
        <label htmlFor="email" style={{color:'#fff'}}> Number</label>
              <input
                type="number"
               
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
         </div>
         </div>
         {/* <div className='row'>
      <div className='col-lg-6'>
        <div className={`input-box ${!passwordsMatch ? 'error' : ''}`}>
          <label htmlFor="email" style={{color:'#fff'}}> Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
      </div>
      <div className='col-lg-6'>
        <div className={`input-box ${!passwordsMatch ? 'error' : ''}`}>
          <label htmlFor="email" style={{color:'#fff'}}> Password Confirmation</label>
          <input
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          {!passwordsMatch && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              Passwords do not match.
            </div>
          )}
        </div>
      </div>
    </div> */}





<div className='row'>
      <div className='col-lg-6'>
        <div className={`input-box ${!passwordsMatch ? 'error' : ''}`}>
          <label htmlFor="password" style={{color:'#fff'}}> Password</label>
          <div className="password-input">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span className="password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEyeSlash style={{color:'#fff'}} /> : <FaEye style={{color:'#fff'}} />}
            </span>
          </div>
        </div>
      </div>
      <div className='col-lg-6'>
        <div className={`input-box ${!passwordsMatch ? 'error' : ''}`}>
          <label htmlFor="passwordConfirmation" style={{color:'#fff'}}> Password Confirmation</label>
          <div className="password-input">
            <input
              type={showConfirmation ? "text" : "password"}
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <span className="password-toggle" onClick={toggleConfirmationVisibility}>
              {showConfirmation ? <FaEyeSlash style={{color:'#fff'}}/> : <FaEye style={{color:'#fff'}}/>}
            </span>
          </div>
          {!passwordsMatch && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              Passwords do not match.
            </div>
          )}
        </div>
      </div>
    </div>
            <div className="input-box mt-2">
           <label htmlFor="email" style={{color:'#fff'}}>Your Email</label>
              <input
                type="email"
               
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
   
      <div>
      <div className="input-box mt-2">
        <label htmlFor="email" style={{ color: '#fff' }}> ReferId </label>
        <input
          type="text"
          value={refid}
          onChange={(e)=>{setRefid(e.target.value)}}
           
        />
      </div>
      <p style={{color:'#fff'}}>{refName == null ? 'undefined' : `${refName}`}</p>
   
      <div
        className="d-flex p-2"
        style={{
          borderRadius: "10px",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          color: "#F23534",
        }}
      >
         
      </div>
    </div>
    </div>
            

    <button
        onClick={() => { passwordsMatch && signup(); }}
        className={`sumit mt-3 ${loading ? 'disabled' : passwordsMatch ? 'active' : ''}`}
        disabled={!passwordsMatch || loading}
      >
        {loading ? "Loading...." : "Register"}
      </button>

        <div class="links">
            <div className="register-link">
              <p>
                Don't have an account? <NavLink to="/" style={{fontSize:'16px',color:'#160961',fontWeight:'600'}}>Login</NavLink>
              </p>
            </div>
        </div>

    </div>

</div>
</div>
    </>
  );
}

export default SignUp;

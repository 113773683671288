import React, { useEffect, useState, useRef } from "react";
import "../component/Text.css";
import Lottie from "lottie-react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import btn from "../Img/coin-btc.png";
import { SiFirewalla } from "react-icons/si";
import { apiurl } from "../env";
import MyChartComponent from "./MyChartComponent";
import { SiWallabag } from "react-icons/si";
import { toast, ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import p14 from "../Img/Animation - 1705480758874.json";
import btn2 from "../Img/coin-dash.png";
import btn1 from "../Img/coin-lite.png";
import btn3 from "../Img/ico-d-1.png";
import btn4 from "../Img/ico-d-2.png";
import btn5 from "../Img/ico-d-3.png";
import { RiShieldUserFill } from "react-icons/ri";
import { GiVendingMachine } from "react-icons/gi";
import { TbBusinessplan, TbMarquee } from "react-icons/tb";
import { MdAnimation } from "react-icons/md";
import { BiArrowToTop } from "react-icons/bi";
import { MdForkLeft } from "react-icons/md";
import { MdOutlineForkRight } from "react-icons/md";
import { GiSesame } from "react-icons/gi";
import { MdOutlineRotate90DegreesCw } from "react-icons/md";
import { MdOutlineCloseFullscreen } from "react-icons/md";


const HomeData = () => {
  // const [searchParams] = useSearchParams();
  const [data, setdata] = useState([]);
  const [textToCopy, setTextToCopy] = useState();
  // const [username, setUsername] = useState(searchParams.get("name") || "");
  
  const [history, setHistory] = useState();
  const [show, setShow] = useState(false);
  // const [refName, setRefName] = useState(searchParams.get("name") || "");
  const inputRef = useRef(null);
  const storedName = localStorage.getItem("name");
  
  const [copySuccess, setCopySuccess] = useState(null);
  const [leftMessage, setLeftMessage] = useState("");
  const [rightMessage, setRightMessage] = useState("");
  const userid = localStorage.getItem("userid");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const handleCopyRight = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Copy failed:", err);
        setCopySuccess(false);
      });
  };

  const handleCopyLeft = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Copy failed:", err);
        setCopySuccess(false);
      });
  };

  const profileApi = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${apiurl}/get-profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result,"kajal");
        setLeftMessage(result.left);
        setRightMessage(result.right);
      })
      .catch((error) => console.log("error", error));
  };

  const handleCopy = () => {
    // Select the text in the input field
    inputRef.current.select();
    // Copy the selected text to the clipboard
    document.execCommand("copy");
  };

  const useractive = async (handleClose) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var formdata = new FormData();

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${apiurl}/activate-plan`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status == "001") {
            toast(result.message);
            handleClose();
          } else if (result.status == "007") {
            toast(result.message);
            handleClose();
          } else {
            toast(result.message);
            handleClose();
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };
  const planhistory = async () => {
    try {
      const response = await fetch(`${apiurl}/my-plans`, {
        method: "POST",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        body: new FormData(),
      });

      const result = await response.json();

      if (result.status === "001") {
        setHistory(result.data);
      }
    } catch (error) {
      console.error("Error fetching plan history:", error);
    }
  };

  const DashbordApi = async () => {
    const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
 

const requestOptions = {
  method: "GET",
  headers: myHeaders, 
  redirect: "follow"
};

fetch(`${apiurl}/get-dashboard`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){
      setTextToCopy(window.location.protocol+'//'+window.location.hostname+'/signUp?refid='+result.data.username+"&name="+result.data.name);


        setdata(result.data)
    }
  })
  .catch((error) => console.error(error));
  }; 

 // Function to handle the copy action
 const handleCopyClick = () => {
  const link = document.getElementById("inp").value;

  // Add a small delay (e.g., 100 milliseconds) before copying
  setTimeout(() => {
      navigator.clipboard.writeText(link)
          .then(() => {
              console.log("Text successfully copied to clipboard");
              alert("Text successfully copied to clipboard")
          })
         
          .catch((err) => {
              console.error("Unable to copy text to clipboard", err);
          });
  }, 100);
}; 
  useEffect(() => { 
    DashbordApi(); 
  }, []);

  useEffect(() => {
     
  }, [ ]);

  return (
    <>
      <ToastContainer />

      <section>
        <div
          className="container-fluid desktop px-3 "
          style={{
            background: "#995B00"
          }}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 p-3">
              <div className="d-flex justify-content-between align-content-center">
                <h6 style={{ color: "white", fontSize: "20px",fontWeight:"bold" }}>
                  Welcome {storedName} 
                </h6>
               <NavLink to="/PurchasePaln"> <button class="button-41" role="button"  >
                  <span class="text text-white">Activate Now</span>
                </button></NavLink>
                
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{
                      color: "white",
                      float: "right",
                      fontSize: "25px",
                      marginLeft: "90px",
                    }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="row  nodejs">
          <div className="col-lg-12 col-sm-12 py-2"  >
          
         
          </div>
        </div>
       
      </section>
      <marquee className="py-0 abc"
              direction="left" 
              scrollamount="10"
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontSize: "20px",
                color: "#fff",
                fontWeight:'500',
                backgroundColor:'hsl(35.74,81.93%,32.55%)', borderRadius:'8px', display:'flex'
              }}
            >
           <div className="px-0" style={{display:'flex',  borderRadius:'8px', alignItems:'center'}}>
            <h1>{data.notice}</h1> 
             </div>
            </marquee>
      <div
        className="container h-10   "
        style={{ height: "88vh", overflowY: "scroll" }}
      >
     
        <div className="mt-3" style={{ marginBottom: "25%" }}>
          <div className="row px-3">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">

                <div class="r4_counter db_box ">
                <div class=" ">
              <div className="row">
                <div className="col-6">
                
                  <h3 class="color-white ">Name :{data.name}</h3>
                  <span>UserId : {data.username}</span>
                </div>
                <div className="col-6 mt-1 text-left">
                
                  <h5 class="color-white ">Created_at :  </h5>
                  <span>{new Date(data.creation_date).toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-')}</span>

                  <span></span>
                </div>
              </div>
              
                </div>

                
              </div>
             
            </div> 
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div class="r4_counter db_box ">
                <div class="cryp-icon">
                  <img src={btn} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.income_wallet_balance}</h3>
                  <span> Income Wallet </span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-2">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <BiArrowToTop />
                </div>
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.total_income}</h3>
                  <span>Total Income</span>
                </div>
              </div>
            </div>
          </div>

        
          <div className="row px-3">
           
             
          </div>
        


          <div className="row px-3">
          <div className="col-lg-4">
              <div class="r4_counter db_box1 ">
                <div class="stats1 py-4">
                  <h3 class="color-white ">Direct Team </h3>
                

                  <h5 className="text-white text-center  "> {data.direct_team}</h5>
                </div>
                
                
              </div>
            </div>
             
            <div className="col-lg-4 col-md-6 col-sm-12  ">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <SiWallabag />
                </div>
                <div class="cryp-icon">
                  <img src={btn2} class="mt-10" alt="" />
                </div>

                <div class="stats pb-3">
                  <h3 class="color-white ">$ {data.total_invested}</h3>
                  <span>Total Invested</span>
                </div>
              </div>
            </div>





            <div className="col-lg-4 col-md-6 col-sm-12  ">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <SiWallabag />
                </div>
                <div class="cryp-icon">
                  <img src={btn2} class="mt-10" alt="" />
                </div>

                <div class="stats pb-3">
                  <h3 class="color-white ">$ {data.daily_income}</h3>
                  <span>Daily Income</span>
                </div>
              </div>
            </div>


            <div className="col-lg-4 col-md-6 col-sm-12  ">
              <div class="r4_counter db_box ">
                <div class="icon-after cc BTC-alt">
                  <SiWallabag />
                </div>
                <div class="cryp-icon">
                  <img src={btn2} class="mt-10" alt="" />
                </div>

                <div class="stats pb-3">
                  <h3 class="color-white ">$ {data.fund_wallet_balance}</h3>
                  <span>Fund Wallet  Balance</span>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div class="r4_counter db_box py-4 ">
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.total_team_building_bonus}</h3>
                  <span>Total Team Building Bonus</span>
                </div>
              </div>
               
            </div>

            <div className="col-lg-4 my-3" >
              <div className="card1"  >
               <h5 style={{textWrap:'nowrap',color:'#000'}}> Referral Link :</h5>  
               <div class="u mt-3" style={{display:'flex', justifyContent:'space-between'}}>
                            <button type='btn' onClick={handleCopyClick} 
                              style={{width:'auto',height:'auto', padding:'0', padding:'4px 10px', backgroundColor:'#1a1a5b', borderRadius:'8px',color:'#fff',fontSize:'16px'}}> Copy Referral Id</button> 
                            
                           <NavLink to="https://t.me/Wealthventure33" target="_blank"> <button className="" type='btn' 
                              style={{width:'auto',height:'auto', padding:'0', padding:'4px 10px', width:'100%', backgroundColor:'#1a1a5b', borderRadius:'8px',color:'#fff',fontSize:'16px'}}> Connect on Telegram</button> </NavLink>
                            
                            </div>
                            <input value={textToCopy} id="inp" type="text"   style={{visibility:"hidden"}}/>
              
              </div>
            </div>



            <div className="col-lg-4">
              <div class="r4_counter db_box py-4 ">
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.automation_booster_income}</h3>
                  <span>Automation Booster Income</span>
                </div>
              </div>
               
            </div>



            <div className="col-lg-4">
              <div class="r4_counter db_box py-4 ">
                <div class="cryp-icon">
                  <img src={btn4} class="mt-10" alt="" />
                </div>

                <div class="stats">
                  <h3 class="color-white ">$ {data.monthly_direct_referral_trading}</h3>
                  <span> Monthly Direct Referral </span>
                </div>
              </div>
               
            </div>

            
      
          </div> 
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body
          style={{
            background: "#000",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            className="Lottie w-25" // Add the class name here
            animationData={p14} // replace with your animation data
            loop
            autoplay
            style={{ marginLeft: "170px" }}
          />
          <p style={{ textAlign: "center", color: "#fff", fontSize: "18px" }}>
            Are You Sure, You Want To Activate <br /> Plan Amount: $30{" "}
          </p>
        </Modal.Body>
        <Modal.Footer style={{ background: "#000" }}>
          <Button variant="secondary" onClick={handleClose}>
            Go Back
          </Button>
          <Button variant="primary" onClick={() => useractive(handleClose)}>
            Activate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HomeData;

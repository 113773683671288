import React from 'react'
import Sidebar from '../Sidebar'
import P2PData from './P2PData'

const Home = () => {
  return (
    <>
           <div className="animated-background" style={{background:"white"}}>
            <div className='container-fulid' > 
            <div className='row'>
                <div className='col-lg-2 col-md-2 p-0' >
                   <Sidebar />
                </div>
                <div className='col-lg-10 col-md-12 justify-content-center align-content-center p-0'>
                   <P2PData/>
                </div>
             
            </div>
        </div>
        </div>
    </>
  )
}

export default Home

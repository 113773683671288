import React, { useState, useEffect } from 'react'
import code from '../Img/qr.png'
import { NavLink, json, useNavigate } from 'react-router-dom';
import { apiurl } from '../env';
import { toast, ToastContainer } from 'react-toastify';



const DepositFundData = () => {
  const Navigate = useNavigate();
  const [data, setdata] = useState('')
  const [getC, setGetC] = useState([]);
  const [amount, setamount] = useState()
  const [AMount, setAMount] = useState()
  const [address, setaddress] = useState('')
  const [password, setPassword] = useState('')
  const [currency, setcurrency] = useState()
  const [currencyname, setcurrencyname] = useState()
  const [qrcode, setcode] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [fund, setfund] = useState();
  const [first, setfirst] = useState(0);
  const [qrcodeUrl, setQrcodeUrl] = useState('');
  const [Apidata, setApidata] = useState(''); 
  const [AddressInput, setAddressInput] = useState(''); 
  const [isCopied, setIsCopied] = useState(false);

  const handleButtonClick = () => {
    setShowSecondForm(!showSecondForm);
  };
  const storedName = localStorage.getItem("name");






  // copy address for deposit Api strt

  const handleCopy = () => {
    const inputField = document.getElementById("addressInput");
    inputField.select();
    document.execCommand("copy");
    setIsCopied(true);

    // Reset the "Copied" message after a brief period (e.g., 2 seconds)
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  // copy address for deposit Api end
  // const getCurrency = async () => {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

  //     const requestOptions = {
  //       method: 'GET',
  //       headers: myHeaders,
  //       redirect: 'follow'
  //     };

  //     const response = await fetch(`${apiurl}/get-currency`, requestOptions);
  //     const result = await response.json();

  //     if (result.status === '001') {
  //       setGetC(result.data);
  //     }
  //   } catch (error) {
  //     console.log('Error fetching currency:', error);
  //   }
  // };
 

  const handledeposit = async()=>{
setLoading(true)
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const raw = JSON.stringify({
  "amount": parseInt(amount)
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${apiurl}/get-deposit-address`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){
      toast(result.message);
      setQrcodeUrl(result.data.api_data.result.qrcode_url);
      setApidata(result.data.api_data.result);
      setcurrencyname(result.data.currency);
      setAddressInput(result.data.api_data.result.address)
      setShowSecondForm(true); 
    }
    else{
      toast(result.message)
      setShowSecondForm(false);
    }
  })
  .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})
  }


  const Withdrawalset = ()=>{
    const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const raw = JSON.stringify({
  "amount": parseInt(AMount),
  "wallet_address": address,
  "password": password
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${apiurl}/request-withdrawal`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){
      toast(result.message);
      setAMount('')
      setaddress('')
      setPassword('')
    }else{
      toast(result.message)
    }
  })
  .catch((error) => console.error(error));
  }


  const DashbordApi = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
     
    const requestOptions = {
      method: "GET",
      headers: myHeaders, 
      redirect: "follow"
    };
    
    fetch(`${apiurl}/get-wallet-balance`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if(result.success == true){
          setdata(result.data);
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    DashbordApi() 
  }, [])

console.log(currency);
  return (
    <>
      <ToastContainer />
      <section >
        <div className="container-fluid desktop px-3" style={{background: "#995B00", width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    <div className='mt-3'>
    <div className='d-flex tabs-container mx-4 mt-5'>
        
        <h6
          style={{ cursor: 'pointer' }}
           onClick={() => {
            setfirst(0);
            window.location.reload();
          }}
          className={first ===  0? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"black",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Deposit Funds</p>

        </h6>

        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"black",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}  >Withdraw Funds</p>
        </h6>
      </div>
    </div>
      <div className='container px-4 mt-1' style={{ height: '78vh', overflowY: 'scroll' }} > 
        {
          first ==1?<>

          <div style={{  background: "linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)", borderRadius: '10px', padding: "10px" }}>
          <div className='row mt-1 px-3'>
      <h5 className='text-white'>Withdraw Funds</h5>
            <div className='col-lg-4'>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp">Fund  Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data?.income_wallet}</h5>
              </div>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp"> Wallet Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data?.fund_wallet}</h5>
              </div>
            </div>
            <div className='col-lg-6' >
  {qrcode == 1 ? 
  
  <div style={{ background: 'linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)', borderRadius: "10px",boxShadow: `rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset` }}>
    <div style={{ background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', borderRadius: '1px' }}>
      <p style={{ padding: '10px', color: '#fff', fontWeight: 600, fontSize: "18px" }}>Add Funds</p>
    </div>
     
 <div className='p-3'>
    <div class="form-group">
    <label for="exampleInputEmail1" style={{fontSize:'18px', color:'#fff'}}> Enter Amount</label>
    <input type="number" class="form-control py-2"   id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount" style={{borderRadius:'8px'}} onChange={(e)=>{setAMount(e.target.value)}}/>
   </div>


   <div class="form-group">
    <label for="exampleInputEmail1" style={{fontSize:'18px', color:'#fff'}}> Enter Address</label>
    <input type="text" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address "  onChange={(e)=>{setaddress(e.target.value)}} style={{borderRadius:'8px'}}/>
   </div>

   <div class="form-group">
    <label for="exampleInputEmail1" style={{fontSize:'18px', color:'#fff'}}> Enter Password</label>
    <input type="password"  class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Amount" onChange={(e)=>{setPassword(e.target.value)}}   style={{borderRadius:'8px'}}/>
   </div>

  
  <div>
    <button className='btn btn-primary w-100 py-2' onClick={()=>{Withdrawalset()}}>Proceed</button>
  </div>
   </div>
  </div> :

    <div style={{ background: '#5a618c', borderRadius: '10px', padding: "10px", justifyContent: "center", alignItems: "center" }}>

      <div className="mt-4 text-center mb-3">
        <p htmlFor="exampleFormControlInput4" className="f">
          Additional Field
        </p>
        <img src={qrcode.qrcode_url} alt='qr code' style={{ marginLeft: "20" }} />
        <p className='mt-3' style={{ color: '#fff' }}>\000</p>
        {/* <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>
            
      </button> */}
      </div>

    </div>

  }
</div>
          </div>
        </div>
        
        
        
        
        
        
        
        
        
        
          </>:first ==0?<>


 

          

          <div style={{background: 'linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)', borderRadius: '10px', padding: "10px" }}>
          <div className='row mt-2 px-3'>
      <h5 className='text-white'>Deposit Funds</h5>
            <div className='col-lg-4'>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp">Fund  Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data.income_wallet}</h5>
              </div>
              <div class="card1 mb-3 p-3 text-center">
                <h5 class="cp">Wallet Balance </h5>
                <hr style={{ color: '#fff' }} />
                <h5 class="ca">${data.fund_wallet}</h5>
              </div>
            </div>
            <div className='col-lg-6'>
  {qrcode == 1 ? <div style={{ background: 'linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)', borderRadius: "10px",boxShadow: `rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset` }}>
    <div style={{ background: 'linear-gradient(to right bottom, #4f525c, #2f323b)', borderRadius: '1px' }}>
      <p style={{ padding: '10px', color: '#fff', fontWeight: 600, fontSize: "18px" }}>Add Funds</p>
    </div>
    <div>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount  
          </label>
          <input
            style={{ color: 'blue', backgroundColor: '#fff',borderRadius:"3px" }} 
            value={amount}
            onChange={(e) => { setamount(e.target.value) }}
            type="text"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />
        
          <label htmlFor="exampleFormControlInput3" className="f mt-3">
            Coin:
          </label>
          <select
                      className="w-100 "
                      style={{
                        background: "#fff",
                        borderRadius: "10px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        color: "#545B7A",
                        border: "none",
                        padding: '10px'
                      }} 
                    >
                      <option value="" disabled selected>Select State </option>
                      <option value="USDT.TRC20"  >Tether USD (Tron/TRC20) </option> 
                    </select>




          <div className=" btn1 mt-4" onClick={handledeposit} style={{cursor:'pointer'}}>
            {loading ? 'Loading...' : 'Proceed'}
                   </div>
        </div>
      )}

      {/* Second Form */}
      {showSecondForm && (
        <div className="mt-4 text-center mb-3">
          <p htmlFor="exampleFormControlInput4" className="f">
            {/* Additional Field */}
          </p>
          {/* <img src={qrcode.qrcode_url} /> */}
     <h5 className='text-white'> Amount :{Apidata?.amount}  </h5>
          {qrcodeUrl && <img src={qrcodeUrl} alt="QR Code" />}
          <h6 className='mt-3' style={{ color: '#fff' }}>Currency : {currencyname}</h6>
   
   <div className='mt-2 mb-4' style={{display:'flex', justifyContent:'center'}}>
          <div className="input-group mb-3  " style={{ width: '90%' }}>
      <input
        id="addressInput"
        type="text"
        className="form-control"
        value={AddressInput}
        onChange={(e) => setAddressInput(e.target.value)}
        placeholder="Recipient's username"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        
      />
      <div className="input-group-append">
        <span
          className={`input-group-text ${isCopied ? 'text-success' : ''}`}
          id="basic-addon2"
          onClick={handleCopy}
          style={{ cursor: 'pointer' }}
        >
          {isCopied ? 'Copied!' : 'Copy'}
        </span>
      </div>
    </div>
    </div> 
          {/* <button className="btn1 mt-4 mx-2 mb-3" onClick={handledeposit }>
            Proceed
          </button> */}
        </div>
      )}
    </div>
  </div> :

    <div style={{ background: '#5a618c', borderRadius: '10px', padding: "10px", justifyContent: "center", alignItems: "center" }}>

      <div className="mt-4 text-center mb-3">
        <p htmlFor="exampleFormControlInput4" className="f">
          Additional Field
        </p>
        <img src={qrcode.qrcode_url} alt='qr code' style={{ marginLeft: "20" }} />
        <p className='mt-3' style={{ color: '#fff' }}>{currencyname}: {qrcode.amount}</p>
        {/* <button className="btn1 mt-4 mx-2 mb-3" onClick={handleButtonClick}>
            
      </button> */}
      </div>

    </div>

  }
</div>
          </div>
        </div>
          </>:null
        }
      </div>


    </>
  )
}

export default DepositFundData
import React from 'react'
import UserData from './UserData'
import Sidebar from '../Sidebar'

const User = () => {
  return (
    <div className="animated-background" style={{background:"white"}}>
    <div className='container-fulid' > 
    <div className='row' >
        <div className='col-lg-2 col-md-2 p-0' >
           <Sidebar />
        </div>
        <div className='col-lg-10 col-md-12 p-0'>
           <UserData/>
        </div>
     
    </div>
</div>
</div>
  )
}

export default User
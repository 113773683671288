import React,{useId, useState}from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './Login.css'
import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { apiurl } from '../../env';
import { ToastContainer, toast } from 'react-toastify';


function Password() {
  const Navigate = useNavigate()
  const [password, setPassword] = useState()
  const [password_c, setPassword_c] = useState()

  const [userid, setuserId] = useState()
  const [loading, setLoading] = useState(false);


  const passwordApi = async () => {
    setLoading(true);
    
    try {
      var myHeaders = new Headers();
  
      var formdata = new FormData();
      formdata.append("password", password);
      formdata.append("password_confirmation", password_c);
      formdata.append("token", localStorage.getItem('token'));
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
  
      const response = await fetch(`${apiurl}/validate-reset-password`, requestOptions);
      const result = await response.json();
      
      console.log(result);
  
      if (result.status === "001") {
        toast.success(result.message);
        await new Promise(resolve => setTimeout(resolve, 1000));
        Navigate('/home'); 
        setPassword('');
        setPassword_c('');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log('error', error);
      toast.error('An error occurred');
    } finally {
      setLoading(false);
    }
  };
  

  return (
  
<>
<div>
  <ToastContainer/>
</div>
   <div class="container app">

<div class="login">

    <h1>New Password</h1>

    <div action="" className='form'>
        <div class="input-box">
        </div>

        <div class="input-box mt-3">
        <label htmlFor="username" style={{color:'#fff'}}>Password</label>
            <input 
            value={password}
            onChange={(e)=>{setPassword(e.target.value)}}
            type="password"
             placeholder="password"/>
          
        </div>

        <div class="input-box mt-3">
        <label htmlFor="username" style={{color:'#fff'}}>Password Confirm</label>
            <input 
            value={password_c}
            onChange={(e)=>{setPassword_c(e.target.value)}}
            type="password"
             placeholder="password"/>
          
        </div>

        <button onClick={()=>{passwordApi()}} className="sumit mt-3">
            {loading==false?"Forget Password":"Loading...."}
            </button>

        <div className="register-link">
              <p>
                Don't have an account? <NavLink to="/">Login</NavLink>
              </p>
            </div>

    </div>

</div>
</div>
</>
  )
}

export default Password

import React,{useState,useEffect} from 'react';
import { apiurl } from '../env';
import { toast,ToastContainer} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Lottie from "lottie-react";
import p14 from "../Img/Animation - 1705480758874.json";

const PurchasePlandata = () => {
    const [first, setfirst] = useState(0); 
    const [history, setHistory] = useState([]) 
    const storedName = localStorage.getItem("name");
    const [show, setShow] = useState(false);
  const [Amount , setAmount] = useState();
  const [Data , setData] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    

    const planPurchage =()=>{
 const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

const raw = JSON.stringify({
  "amount": parseInt(Amount)
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${apiurl}/trade-plan`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){
      toast(result.message)
      setAmount('');
    }
    else{
      toast(result.message)
    }
  })
  .catch((error) => console.error(error));
    }


    const planHistory =()=>{
      const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
 

const requestOptions = {
  method: "GET",
  headers: myHeaders, 
  redirect: "follow"
};

fetch(`${apiurl}/trade-history`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    setData(result.data)
  })
  .catch((error) => console.error(error));
    }

 
    useEffect(() => {
      planHistory();
    }, [])
    
  return (
  <div>
  <ToastContainer/>
  <section >
        <div className="container-fluid desktop px-3" style={{background: "#995B00",  width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

    <div className='container-fluid mt-3 bahubali' >  
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12 col-sm-12'>

        <div className='d-flex tabs-container'>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(0)}
          className={first === 0 ? 'active' : ''}
        >
          <p className='px-3 mt-2' style={{color:"black",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Plan Purchase</p>
        </h6>
        <h6
          style={{ cursor: 'pointer' }}
          onClick={() => setfirst(1)}
          className={first === 1 ? 'active' : ''}
        >
        <p className='px-3 mt-2' style={{color:"black",fontSize:'18px',justifyContent:'center',alignItems:'center',fontFamily:'Rubik'}}>Plan History</p>

        </h6>
      </div>

          
        {
        first==0?<>
                <div className=' row mt-4'>


              <div className='col-lg-6 col-sm-12 mt-3 '>
              <div className='pt-3'  >
    <label for="exampleInputEmail1" class="form-label text-dark" style={{fontSize:'16px'}}> Enter Amount </label>
    <input type="number" class="form-control py-2" id="exampleInputEmail1" placeholder='Enter Amount' aria-describedby="emailHelp" style={{borderRadius:'8px'}}
     onChange={(e)=>{setAmount(e.target.value)}}/>
   </div>


   <div style={{display:'flex', justifyContent:'center'}}> 
   <button class="richblue mt-4 py-2" onClick={()=>{handleShow()}}>Plan purchase</button>
   </div>
              </div>

              <div className='col-lg-6 col-sm-12 mt-4'>
              
              </div>
                </div>
        </>:first==1? <>
        <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12' style={{}}>
 
<div className='container' >
<div className='row mt-3 text-center' style={{}}>
<div className='col-lg-12'>
  
<div class="table-responsive text-nowrap m-3 p-3" style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',background:"#995B00"}}>
  <div className='res'>
  <table class="table table-striped ">
<thead>
  <tr>
    <th>#</th>
    <th>User Name</th>
    <th>Amount</th> 
    <th>Date/Time</th>
  </tr>
</thead>
  <tbody>
  {Data?.length > 0 ? (
    Data?.map((res, index) => (
          <tr key={index}>
            <th>{index + 1}</th>
            <th>{res.username}</th> 
            <th>{res.amount}</th> 
            <th>{res.created_at}</th>
          </tr>
          ))
  ) : (
    <tr>
      <td colSpan="8">No data found</td>
    </tr>
  )}
        </tbody>
</table>
  </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>

        </>:null
    }
        </div>
      </div>
    </div>
    </div>







    {/* modal start */}
    <Modal className='pt-5' show={show} onHide={handleClose} style={{backdropFilter:"blur(5px)"}}>
         
    <Modal.Body
          style={{
            background: "#000",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            className="Lottie w-25" // Add the class name here
            animationData={p14} // replace with your animation data
            loop
            autoplay
            style={{ marginLeft: "170px" }}
          />
          <p style={{ textAlign: "center", color: "#fff", fontSize: "18px" }}>
            Are You Sure, You Want To Activate <br />{" "}
          </p>
        </Modal.Body>
        <Modal.Footer style={{ background: "#000" }}>
          <Button variant="secondary" onClick={handleClose}>
            Go Back
          </Button>
          <Button variant="primary" onClick={() => {planPurchage(); handleClose();  }}
>
            Activate
          </Button>
        </Modal.Footer>

       
      </Modal>
    {/* modal end */}

    </div>

  )
}

export default  PurchasePlandata
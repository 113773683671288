import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { apiurl } from '../../env';
import { toast, ToastContainer } from 'react-toastify';
import OtpInput from 'react-otp-input'



const OTP = () => {
  const Navigate = useNavigate()
    const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userid");


  const [useName, setUseName] = useState();
  const [ConfirmPass, setConfirmPass] = useState();

//   const Otp = async()=>{
//     setLoading(false)
//     var myHeaders = new Headers();

// var formdata = new FormData();
// formdata.append("otp", otp);
// formdata.append("username", userId);

// var requestOptions = {
//   method: 'POST',
//   headers: myHeaders,
//   body: formdata,
//   redirect: 'follow'
// };

// fetch(`${apiurl}/validate-otp`, requestOptions)
//   .then(response => response.json())
//   .then(result => {console.log(result)
//    if(result.status){
//      toast.success(result.message)
//     Navigate('/Password')
//     setOtp('')
//     localStorage.setItem("token", result.token);

//    }
//    else{
//     toast.error(result.message)

//    }
//   })
//   .catch(error => console.log('error', error)).finally(()=>{setLoading(false)})

//   }


const Otp = () =>{
  const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  "username": useName,
  "otp": otp,
  "new_password": ConfirmPass,
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${apiurl}/password-reset-with-otp`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){
      toast(result.message);
      Navigate('/')
    }else{
      toast(result.message);
    }
  })
  .catch((error) => console.error(error));
}

  const renderInput = (inputProps) => (
    <input
      {...inputProps}
      style={{
        fontSize: '20px', 
        textAlign: 'center',
        color: '#000', // Your desired text color
        backgroundColor: 'rgba(255, 255, 255, 0.2)  ',
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding:'0px',
        padding:'7px'
         
        
      }}
    />
  );

  console.log(otp);

  return (
    <>
    <div>
      <ToastContainer/>
    </div>

    <div class="container app">
    
    <div class="login">
    
        <h1>OTP</h1>
    
        <div action="" className='form'>
            <div class="input-box">
            </div>


            <div class="form-group mt-4">
    <label for="exampleInputEmail1" className='text-white'>Enter UserName</label>
    <input type="text" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter UserName" style={{borderRadius:'8px', backgroundColor:'transparent', color:'#fff'}}      onChange={(e)=>{setUseName(e.target.value)}}/>
    
  </div>

    
            <div class="input-box mt-3">
            <label htmlFor="username" style={{color:'#fff'}}>Enter Your OTP</label>
            <OtpInput
            
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span>-</span>}
      renderInput={renderInput}
      className="otp-input"
    />

            </div>



            
            <div class="form-group mt-4">
    <label for="exampleInputEmail1" className='text-white'>Enter New Password</label>
    <input type="password" class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter New Password"
     style={{borderRadius:'8px', backgroundColor:'transparent', color:'#fff'}}      onChange={(e)=>{setConfirmPass(e.target.value)}}/>
    
  </div>
    
            <button className="sumit mt-4" onClick={()=>{Otp()}}>
                {loading==false?"Update Password":"Loading...."}
                </button>
    
            <div className="register-link">
                  <p style={{color:'#fff'}}>
                    Don't have an account? <NavLink to="/">Login</NavLink>
                  </p>
                </div>
    
        </div>
    
    </div> 
    </div>
       
    </>
  )
}

export default OTP;
import React, { useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { apiurl } from "../../env";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function Login() {
  const [searchParams] = useSearchParams();
  const Navigate = useNavigate();
  const [email, setEmail] = useState(searchParams.get("un") || "");
  const [password, setPassword] = useState(searchParams.get("pw") || "");
  const [loading, setLoading] = useState(false);


 
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const login = async () => {
    setLoading(true);
    var myHeaders = new Headers();

    var formdata = new FormData();
    formdata.append("username", email);
    formdata.append("password", password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${apiurl}/login`, requestOptions);
      const result = await response.json();

      if (result.success === true) {
        localStorage.setItem("token", result.data.token);
        toast.success(result.message);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        Navigate("/Home");
      } else {
        toast(result.message);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const style = {
    "--clr": "red",
  };

  return (
    <>
      <ToastContainer />
      <div class="container app">
        <div class="login">
          <h1 style={{ color: "white", fontSize: "42px" }}>Login </h1>

          <div action="" className="form">
            <div class="input-box"></div>

            <div class="input-box ">
              <label htmlFor="email" style={{ color: "#fff" }}>
                Enter Your Username
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                placeholder="Username"
              />
            </div>
            <div class="input-box mt-3">
              <label htmlFor="password" style={{ color: "#fff" }}>
                Enter Your Password
              </label>
              {/* <input
                value={password}
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              /> */}

<div className="password-input-container">
      <input
        value={password}
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <span
        className="toggle-password-visibility"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? <FaEyeSlash style={{color:'#fff'}}/> : <FaEye style={{color:'#fff'}}/>}
      </span>
    </div>
              <p>
                {" "}
                <NavLink
                  to="ForgotPassword"
                  style={{ fontSize: "13px", color: "#230f80" }}
                >
                  Forgot password
                </NavLink>{" "}
              </p>
            </div>

            <button
              onClick={() => {
                login();
              }}
              className="sumit mt-3"
            >
              {loading == false ? "login" : "Loading...."}
            </button>

            <div class="links">
              <div className="register-link">
                <p style={{ color: "#fff" }}>
                  Don't have an account?{" "}
                  <NavLink
                    to="signup"
                    style={{
                      fontSize: "21px",
                      color: "#230f80",
                      fontWeight: "600",
                    }}
                  >
                    Signup
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

import React,{useState,useEffect} from 'react';
import { apiurl } from '../env';
import { toast,ToastContainer} from 'react-toastify'; 
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import './Text.css'
import OTPInput, { ResendOTP } from "otp-input-react";
const P2PData = () => {
  const [searchParams] = useSearchParams();
  const [OTP, setOTP] = useState("");

    const [first, setfirst] = useState(0);
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [username, setUsername] = useState(searchParams.get("refid") || '')
    const [amount, setamount] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(false);
    const [data,setdata] = useState([])
    const [data1,setdata1] = useState([])
    const [data2,setdata2] = useState([])
    const [fund, setfund] = useState()
    const [fund1, setfund1] = useState()
    const storedName = localStorage.getItem("name");
    const [refName, setRefName] = useState(searchParams.get("name") || '');
     const [Amount, setAmount] = useState()
     const [Pass, setPass] = useState()
     const [Funddata, setFundata] = useState([])
      const [showOTPField, setShowOTPField] = useState(false);
      const [count, setCount] = useState('');




     const DashbordApi = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
       
      const requestOptions = {
        method: "GET",
        headers: myHeaders, 
        redirect: "follow"
      };
      
      fetch(`${apiurl}/get-wallet-balance`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success == true){
            setdata1(result.data); 
          }
        })
        .catch((error) => console.error(error));
    }
  

const P2p_TransFer = async() =>{
try {
  const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}` );

const raw = JSON.stringify({
  "amount":  parseInt(amount),
  "password": password,
  "userid":  username,
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${apiurl}/p2p-transfer`, requestOptions)
  .then((response) => response.json())
  .then((result) =>{
    if(result.success == true){
      toast(result.message)
      setUsername('')
      setamount('')
      setPassword('')
      setCount('second')
      setShowOTPField(true)

    }else{
      toast(result.message)
    }
  })
  .catch((error) => console.error(error));
} catch (error) {
  
}

}
 


const P2PHistory = async()=>{
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
    
    
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders, 
      redirect: "follow"
    };
    
    fetch(`${apiurl}/p2p-history`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      
    if(result.success == true){
      setdata(result.data.history)
    }
  })
  .catch((error) => console.error(error));
  } catch (error) {
    
  }
}




const FundtoIncome = () =>{
  const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`); 

const raw = JSON.stringify({
  "amount": parseInt(Amount),
  "password": Pass
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${apiurl}/income_to_fund`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){
        toast(result.message)
    }else{
      toast(result.message)
    }
  })
  .catch((error) => console.error(error));
} 



const fundHistory = () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

  

  const requestOptions = {
    method: "GET",
    headers: myHeaders, 
    redirect: "follow"
  };

  fetch(`${apiurl}/income_to_fund_history`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result,'hello gajju')
      if (result.success === true) {
        setFundata(result.data.history);   
      }
    })
    .catch((error) => console.error(error));
};


const SendOtp = () =>{
  try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
      
      
      
      const requestOptions = {
        method: "GET",
        headers: myHeaders, 
        redirect: "follow"
      };
      
      fetch(`${apiurl}/get-p2p-otp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        
      if(result.success == true){
        toast(result.message)
        setCount('second')
      }else{
        toast(result.message)
   alert('hello')
      }
    })
    .catch((error) => console.error(error));
    } catch (error) {
      
    }
}



const ConfirmOtp = async() =>{
  try {
    const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}` );

const formdata = new FormData();
formdata.append("otp", OTP);

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: formdata,
  redirect: "follow"
};

fetch(`${apiurl}/otp-confirm`, requestOptions)
  .then((response) => response.json())
  .then((result) => {
    if(result.success == true){ 
      toast(result.message)
      P2p_TransFer();

      setTimeout(() => {
        setOTP('')
        setCount('first')
      }, 2000);
      setOTP('')
    }else{ 
      setOTP('')
       
    }
  })
  .catch((error) => console.error(error));
  } catch (error) {
    
  }
}

// const ConfirmOtp =() =>{ 

//   const myHeaders = new Headers();
//   myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
   
//   const formdata = new FormData();  
//   formdata.append("otp", OTP);

// const requestOptions = {
//   method: "POST",
//   body: formdata,
//   redirect: "follow"
// };

// fetch(`${apiurl}/otp-confirm`, requestOptions)
//   .then((response) => response.json())
//   .then((result) => {
//     if(result.status === true){
//       toast(result.message)
//       P2p_TransFer()
//     }else{
//       toast(result.message)
//       alert('hello ') 
//     }
//   })
//   .catch((error) => console.error(error));
// }

  
 useEffect(()=>{
  P2PHistory();
  fundHistory();
  DashbordApi();
  setCount('first')
 },[]) 


const handleInputChange = (e) => {
  setUsername(e.target.value); 
};

  return (
  <div>
  <ToastContainer/>
  <section >
        <div className="container-fluid desktop px-3" style={{ background: "#995B00",width: '100%' }}>
          <div className="row">
            <div className="col-lg-10 col-md-12 p-3">
              <p style={{ textAlign: 'center', color: '#fff', fontSize: '20px' }}>Welcome {storedName}</p>
            </div>
            <div className="col-lg-2 col-md-12">
              <div style={{ width: "100%", padding: "10px" }}>
                <NavLink to="/User">
                  <i
                    className="bi bi-person-circle "
                    style={{ color: "#fff", float: "right", fontSize: "25px" }}
                  ></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section> 
    <div className='container-fluid  mt-4 '  >
    <div className='row'>
   <div className='col-lg-12'>

    <div className='row'>
      <div className='col-12'>
        
      </div>
    </div>
   <div  >
 
   <div className='d-flex tabs-container' style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
  <h6
    style={{ cursor: 'pointer', display: 'inline-block',   }}
    onClick={() => setfirst(0)}
    className={` ${first === 0 ? 'active' : ''} hello`}
  >
    <p className='px-3 mt-2' style={{ color: 'black', fontSize: '18px', justifyContent: 'center', alignItems: 'center', fontFamily: 'Rubik' }}>P2P Transfer</p>
  </h6>
  <h6
    style={{ cursor: 'pointer', display: 'inline-block',  className:"hello" }}
    onClick={() => setfirst(1)}
    className={` ${first === 1 ? 'active' : ''} hello`}
  >
    <p className='px-3 mt-2' style={{ color: 'black', fontSize: '18px', justifyContent: 'center', alignItems: 'center', fontFamily: 'Rubik' }}>Transfer History</p>
  </h6>
  <h6
    style={{ cursor: 'pointer', display: 'inline-block',  className:"hello" }}
    onClick={() => setfirst(2)}
    className={` ${first === 2 ? 'active' : ''} hello3`}
  >
    <p className='px-3 mt-2' style={{ color: 'black', fontSize: '18px', justifyContent: 'center', alignItems: 'center', fontFamily: 'Rubik' }}>Income to Fund Wallet</p>
  </h6>
  <h6
    style={{ cursor: 'pointer', display: 'inline-block',  className:"hello"}}
    onClick={() => setfirst(3)}
    className={` ${first === 3 ? 'active' : ''} hello4`}
  >
    <p className='px-3 mt-2' style={{ color: 'black', fontSize: '18px', justifyContent: 'center', alignItems: 'center', fontFamily: 'Rubik' }}>Income to Fund history </p>
  </h6>
</div>



      </div>
   <div style={{ height: "88vh", overflowY: "scroll", }}> 
         

    
{
  first === 0 ? (
    <>
       <div className='mt-3'>
            <div class="container mb-5">
                <div class="row m-1 p-3 " style={{background:"linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)",borderRadius:"5px"}}>
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 text-center pt-5">
                        <div class="card1" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp">Fund  Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${data1.fund_wallet}</h5>
                            </div>
                        </div>
                        <div class="card1 mt-4 mb-5" style={{width:"100%",padding:'20px'}}>
                            <div class="ca">
                                <h5 class="cp"> Wallet Balance</h5>
                                <p style={{color:"#fff",borderBottom:'1px solid #fff'}}/>
                                <h5 class="ca">${data1.income_wallet}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-6 col-sm-6">
                    </div>
                    <div className='col-lg-6 mt-2'>
 <div style={{ background: 'linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)',borderRadius:"10px",
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
<div style={{background: 'linear-gradient(to right bottom, #4f525c, #2f323b)',borderRadius:'1px'}}>
<p style={{padding:'15px',color:'#fff',fontWeight:600,fontSize:"18px"}}>P2P Transfer </p>
</div>
        <div   style={{display:count == "first"?"block":"none"}}>
      {!showSecondForm && (
        <div className="mb-3 p-4">
          <label htmlFor="exampleFormControlInput1" className="f">
            Userid
          </label>
          <input
    style={{color:'blue',background:""}}
    type="text"
    value={username}
    onChange={handleInputChange}
    className="form-control"
    id="exampleFormControlInput1"
    placeholder="Enter User ID"
/>
<p style={{color:'#fff'}}>{refName == null ? 'undefined' : `${refName}`}</p>

<label htmlFor="exampleFormControlInput2" className="f  ">
            Enter Password
          </label>
          <input
          style={{color:'blue'}}
          value={password}
          onChange={(e)=>{setPassword(e.target.value)}}
            type="text"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter password"
          />

          <label htmlFor="exampleFormControlInput2" className="f mt-3">
            Enter Amount
          </label>
          <input
          style={{color:'blue'}}
          value={amount}
          onChange={(e)=>{setamount(e.target.value)}}
            type="number"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter Amount"
          />

          {/* <label htmlFor="exampleFormControlInput3" className="f mt-3">
            Coin:
          </label>
          <select className="form-select mt-2" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select> */}

          <button className="btn1 w-100 mt-4 mb-5" onClick={()=>{SendOtp()}} style={{cursor:'pointer'}}>
          {loading ? 'Loading...' : 'Procced  '}
          </button> 
        </div> 
      )}
    </div>  

    <div className='p-3' style={{display:count == "second"?"block":"none"}}>   
          <h6 className='mt-4'>Enter Otp</h6>
          <div style={{display:'flex', justifyContent:'center'}}>
          <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} className="mt-4" />
       </div>
       <button className='btn btn-primary mt-4 mb-5 w-100' onClick={()=>{ConfirmOtp()}}>Submit</button>
         </div>
    </div>
</div>
                </div>
            </div>
            </div>

           
    </>
  ) : first === 1 ? (
    <> 
  <div className='container' >
<div className='row mt-3 text-center'>
<div className='col-lg-12' style={{}}>
  
<div class="table-responsive text-nowrap  p-3" style={{borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',background:"#995B00"}}>
  <div className='res'>
  <table class="table table-striped ">
<thead>
  <tr>
    <th>#</th>
    <th>fromuser</th>
    <th>touser</th>
    <th>amount</th>
    <th>created_at</th>
  </tr>
</thead>
<tbody>
  
  {data.length > 0 ? (
    data?.map((res, index) => (
      <tr >
        <th>{index + 1}</th>
        <th>{res.from_user} </th>
        <th>{res.to_user}</th>
        <th>{res.amount}</th>
        <th>{res.created_at}</th> 
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="8" className='text-white'>No data found</td>
    </tr>
  )}
</tbody>
</table>
  </div>
  </div>
</div>
</div>
</div>
    </>
  ) : first === 2 ? (
    <>

    <div className='container mt-5'>
     <div className='row'>
     <div className='col-lg-7 col-sm-12 m-auto '>
      <div className='card1 py-4' style={{ background: 'linear-gradient(90deg, rgba(153,91,0,1) 0%, rgba(78,90,76,1) 99%)',borderRadius:"10px",
  boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset'}}>
       <div className='card-body1'>
          
           <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label text-white " style={{fontSize:'18px'}}>Amount</label>
    <input type="email" class="form-control py-2"  value={Amount} id="exampleInputEmail1" aria-describedby="emailHelp" style={{borderRadius:'8px'}} onChange={(e)=>{setAmount(e.target.value)}}/> 
  </div>
          
           <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label text-white" style={{fontSize:'18px'}}>Password</label>
    <input type="email" class="form-control py-2" value={Pass} id="exampleInputEmail1" aria-describedby="emailHelp"  style={{borderRadius:'8px'}} onChange={(e)=>{setPass(e.target.value)}}/> 
  </div>

  <div>
    <button className='btn btn-primary w-100 py-2 mt-3' onClick={()=>{FundtoIncome()}}>  Self Transfer</button>
  </div>

       </div>
      </div>
     </div>
     </div>
    </div>
     
      
    </>
  ) : first === 3 ? (
    <>

    <div className='container mt-5'>
     <div className='row'>
     <div className='col-lg-12 col-sm-12   '>
        
     <table class="table">
  <thead  >
    <tr>
      <th scope="col">#</th>
      <th scope="col">From User</th>
      <th scope="col">To User</th>
      <th scope="col">Amount</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>

  {
  Funddata?.map((res, index) => (
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td>{res.from_user}</td>
      <td>{res.to_user}</td>
      <td>{res.amount}</td>
      <td>{res.created_at}</td>
    </tr>
  ))
}
  
  </tbody>
</table> 
     </div>
     </div>
    </div>
     
      
    </>
  ) : null
}
            </div>
            </div>
    </div>
    </div>

    </div>

  )
}

export default  P2PData
    